import React from 'react'
import { Link } from 'gatsby'

import style from './style.module.scss'
import logo from '../../images/logo-m4.svg'
import contactFormArrow from '../../images/contact-form-arrow.png'

const PublicityContactPage = () => (
  <div className={style.publicityContact}>
    <Link to="/" className={style.logom4}>
      <img src={logo} alt="Logo Studio M4" />
    </Link>
    <div>
      <div className={style.pageDetails}>
        <div>Quero parceria do StudioM4 para</div>
        <h3>Publicidade</h3>
        <img src={contactFormArrow} alt="form arrow" />
        <p>
          Tem interesse em anunciar os produtos da sua marca em nossas redes
          sociais para um público engajado e interessado em arquitetura e design
          de interiores? Baixe nosso mídia kit e entre em contato conosco pelo
          e-mail: <strong>contato@studiom4.com.br</strong>.
        </p>
      </div>
      <button
        type="button"
        className={style.downloadButton}
        onClick={() => {
          window.open(process.env.GATSBY_KIT_MIDIA_LINK, '_blank')
          window.location = '/sucesso-arquivo-publicidade'
        }}
      >
        Baixar Mídia Kit
      </button>
    </div>
  </div>
)

export default PublicityContactPage
