import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PublicityContactPage from '../components/PublicityContactPage'

import '../styles/global.scss'

const PublicityContact = () => (
  <Layout>
    <SEO title="Contato" keywords={[`gatsby`, `application`, `react`]} />
    <PublicityContactPage />
  </Layout>
)

export default PublicityContact
